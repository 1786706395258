<template>
  <section>
    <b-card
      no-body
      class="mb-0"
      header-tag="header"
    >
      <div class="card-body">
        <div class="d-sm-flex justify-content-between mb-1">
          <div>
            <button
              class="compact ui button"
              @click="refreshCommon"
            >
              <refresh-cw-icon
                size="12"
                class="custom-class"
                :stroke-width="3"
              />
              Tải lại
            </button>
            <button
              v-if="checkQuyenBanGiao"
              class="compact ui btn-primary button"
              :class="selectedNumber > 0 ? '' : 'disabled'"
              @click="onBGBtn"
            >
              <corner-down-right-icon
                size="12"
                class="custom-class"
                :stroke-width="3"
              />
              Bàn giao hồ sơ
            </button>
          </div>
          <div>
            <filter-icon
              size="20"
              class="custom-class cursor-pointer"
              :stroke-width="2"
              @click="onFilter"
            />
          </div>
        </div>
        <div
          v-show="isShow"
        >
          <advanced-search
            ref="formFilter"
            class="mt-2"
            @advanced-search="filterDanhSachHoSo"
          />
        </div>
        <good-table
          ref="hoSo"
          class="mt-1"
          :class="checkAllQuyen ? '' : 'fixed-column'"
          :columns="getColumns"
          :rows="rows"
          :total="tongSoHoSo"
          @selected-item="onSelectRow"
          @column-filter="filterDanhSachHoSo"
          @delete-item="setItemUpdate"
          @page-change="pageChange"
        >
          <template
            slot="custom-row"
            slot-scope="props"
          >
            <div
              v-if="props.props.column.field === 'tenTrangThai'"
              class="custom-column"
            >
              <div>{{ formatDateTime(props.props.row.ngayNop) }}</div>
              <div>{{ formatDateTime(props.props.row.ngayHenTraKq) }}</div>
              <!-- eslint-disable vue/no-v-html -->
              <div v-html="props.props.row.tenLoaiTrangThai" />
              <!--eslint-enable-->
              <div
                v-if="props.props.row.thoiGianDenHanTraKQ"
                :class="props.props.row.thoiGianDenHanTraKQ.indexOf('Quá hạn') >= 0 ? 'text-red-cor' : 'text-green-cor'"
              >
                {{ props.props.row.thoiGianDenHanTraKQ }}
              </div>
            </div>
            <span v-if="props.props.column.field === 'code'">
              <a
                v-if="checkQuyenChiTiet"
                href="javascript:;"
                @click="detailHoSo(props.props.row)"
              >{{ `${props.props.row.code}` }}</a>
              <div v-else>
                {{ props.props.row.code }}
              </div>
              <div>
                <cloud-icon
                  size="15"
                  class="custom-class"
                /> {{ props.props.row.kieuTiepNhan }}
              </div>
              <div v-if="props.props.row.dichVuChuyenPhatKetQua">
                <send-icon
                  size="15"
                  class="custom-class"
                /> Dịch vụ chuyển phát kết quả
              </div>
            </span>
            <div
              v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
              class="custom-column"
            >
              <div v-if="props.props.row.tenTrangThai && !props.props.row.tenTrangThai.indexOf('Kết thúc')">
                <div class="text-yellow-cor">
                  {{ props.props.row.tenTrangThai }}
                </div>

              </div>
              <div v-else>
                <div>{{ formatDateTime(props.props.row.thoiGianBatDauTrangThai) }}</div>
                <div>{{ formatDateTime(props.props.row.thoiGianKetThucTrangThai) }}</div>
                <div class="text-yellow-cor">
                  {{ props.props.row.tenTrangThai }}
                </div>
                <div
                  v-if="props.props.row.thoiGianDenHanKetThucTrangThai"
                  :class="props.props.row.thoiGianDenHanKetThucTrangThai.indexOf('Còn hạn') >= 0 ? 'text-green-cor' : 'text-red-cor'"
                >
                  {{ props.props.row.thoiGianDenHanKetThucTrangThai }}
                </div>
              </div>

            </div>
            <div v-else-if="props.props.column.field === 'chuHoSo_HoVaTen'">
              <div v-if="props.props.row.chuHoSo_HoVaTen">
                <user-icon size="14" /> {{ props.props.row.chuHoSo_HoVaTen }}
              </div>
              <div
                v-if="props.props.row.chuHoSo_DiaChiDayDu"
                class="text-top word-break"
              >
                <map-pin-icon size="14" /> {{ props.props.row.chuHoSo_DiaChiDayDu }}
              </div>
            </div>
            <div v-else-if="props.props.column.field === 'tenThuTucHanhChinh'">
              {{ props.props.row.tenThuTucHanhChinh }}
            </div>
          </template>
          <template
            slot="custom-filter"
            slot-scope="props"
          >
            <treeselect
              v-if="props.props.column.field === 'tenThuTucHanhChinh'"
              id="linhVuc"
              ref="tenThuTucHanhChinh"
              v-model="filter.dataTTHCId"
              v-format-v-select
              :default-options="danhSachThuTucHanhChinh"
              placeholder="Chọn thủ tục hành chính"
              :limit="0"
              multiple
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              loading-text="Đang tìm kiếm"
              :normalizer="normalizer"
              :match-keys="['label', 'label2']"
              class="vtreeselect-chooser"
              :load-options="onQueryChange"
              :async="true"
              :clear-on-select="true"
              :before-clear-all="clearTreeSelect"
              @select="$refs.tenThuTucHanhChinh.resetSearchQuery(); nextTickTreeSelect()"
              @deselect="nextTickTreeSelect()"
            >
              <label
                slot="option-label"
                slot-scope="{ node, shouldShowCount, count, labelClassName }"
                :class="labelClassName"
                :title="node.label"
              >
                {{ node.label }}
              </label>
            </treeselect>
            <treeselect
              v-else-if="props.props.column.field === 'tenLoaiTrangThai'"
              v-model="filter.trangThaiConHanQuaHan"
              v-format-v-select
              class="vtreeselect-chooser"
              :options="danhSachThoiHan"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder="Chọn thời hạn"
              :clear-on-select="true"
              :before-clear-all="clearTreeSelect"
              @select="nextTickTreeSelect()"
              @deselect="nextTickTreeSelect()"
            />
            <treeselect
              v-else-if="props.props.column.field === 'tenTrangThai'"
              v-model="filter.loaiTrangThaiConHanQuaHan"
              v-format-v-select
              class="vtreeselect-chooser"
              :options="danhSachThoiHan"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder="Chọn thời hạn"
              :clear-on-select="true"
              :before-clear-all="clearTreeSelect"
              @select="nextTickTreeSelect()"
              @deselect="nextTickTreeSelect()"
            />
          </template>
          <template
            slot="actions"
            slot-scope="props"
          >
            <div class="d-flex justify-content-center">
              <div>
                <eye-icon
                  v-if="checkQuyenChiTiet"
                  v-b-tooltip.hover.v-secondary
                  title="Chi tiết"
                  size="16"
                  class="custom-class mr-1 cursor-pointer"
                  @click="detailHoSo(props.props.row)"
                />
                <printer-icon
                  v-if="checkQuyenIn"
                  v-b-tooltip.hover.v-secondary
                  title="In"
                  size="16"
                  class="custom-class cursor-pointer"
                  stroke="#606266"
                  @click="onPrint(props.props.row)"
                />
              </div>
            </div>
          </template>
        </good-table>
      </div>
    </b-card>
    <commonModal
      ref="commomnModal"
      :title="title"
      :hide-header="hideHeader"
    >
      <component
        :is="componentName"
        id="componentPrint"
      />
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="hideModal"
          >
            Đóng
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mr-1"
            @click="onPrintModal"
          >
            In phiếu bàn giao
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mr-1"
            @click="onExportModal"
          >
            Xuất ra file
          </b-button>
        </div>
      </div>
    </commonModal>
    <commonModal
      ref="printModal"
      :title="'Mẫu phiếu in'"
    >
      <b-list-group style="max-height: 350px; overflow: auto;">
        <b-list-group-item
          v-for="item in danhSachNhomPhieu"
          :key="item.id"
          href="#"
          @click="onClickToPrinter(item)"
        >{{ item.tenMauPhieu }}</b-list-group-item>
      </b-list-group>
      <div slot="footer">
        <div class="w-100">
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="$refs.printModal.hideModal()"
          >
            Đóng
          </b-button>
        </div>
      </div>
    </commonModal>
    <b-modal
      id="InTheoBieuMau"
      ref="InTheoBieuMau"
      :hide-footer="true"
      :hide-header="true"
      :no-close-on-backdrop="true"
      size="lg"
    >
      <div
        v-if="mauPhieu"
        ref="docBody"
        v-html="mauPhieu.duongDan"
      />
      <div>
        <div style="text-align: right; margin-top: 1.5rem">
          <b-button
            style="margin-right: 1rem"
            variant="primary"
            size="sm"
            @click="onClickToPrint"
          >
            In biên nhận
          </b-button>
          <b-button
            style="margin-right: 1rem"
            variant="primary"
            size="sm"
            @click="onExportFileWord"
          >
            Kết xuất
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="hide"
          >
            Đóng
          </b-button>
        </div>
      </div>
      <iframe
        id="printing-frame"
        name="print_frame"
        style="display:none;"
      />
    </b-modal>
    <iframe
      id="printf"
      name="printf"
      class="d-none"
    />
    <bien-nhan-ho-so ref="bienNhanHoSo" />
    <xu-ly-ho-so
      ref="xuLyHoSo"
      @reload-page="filterDanhSachHoSo"
    />
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import END_POINTS from '@/api/endpoints'
import {
  VBTooltip,
  BCard,
  BModal,
  BButton,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import {
  SearchIcon,
  FilterIcon,
  CloudIcon,
  CornerDownRightIcon,
  RefreshCwIcon,
  SendIcon,
  EyeIcon,
  PrinterIcon,
  UserIcon,
  MapPinIcon,
} from 'vue-feather-icons'
import GoodTable from '@/components/GoodTable.vue'
import commonModal from '@/modules/xu-ly-ho-so/components/modal/CommonModal.vue'
import addCssSemantic from '@/mixins/mixins'
import BanGiaoNhieuHoSoForm from '@/modules/xu-ly-ho-so/components/form/BanGiaoNhieuHoSoForm.vue'
import InPhieuBanGiaoForm from '@/modules/xu-ly-ho-so/components/form/InPhieuBanGiaoForm.vue'
import AdvancedSearch from '@/components/AdvancedSearch.vue'
import { formatDateTime, removeDiacritical } from '@/utils/index'
import BienNhanHoSo from '@/modules/tiep-nhan-ho-so/components/pages/truc-tiep/BienNhanHoSo.vue'
import XuLyHoSo from '@/modules/xu-ly-ho-so/components/common/XuLyHoSo.vue'
import _debounce from 'lodash/debounce'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'DanhSachHoSo',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    SearchIcon,
    FilterIcon,
    PrinterIcon,
    CornerDownRightIcon,
    RefreshCwIcon,
    EyeIcon,
    UserIcon,
    MapPinIcon,
    BModal,
    BListGroup,
    BListGroupItem,
    CloudIcon,
    SendIcon,
    commonModal,
    BCard,
    BButton,
    BanGiaoNhieuHoSoForm,
    InPhieuBanGiaoForm,
    GoodTable,
    AdvancedSearch,
    BienNhanHoSo,
    XuLyHoSo,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      hoSo: null,
      mauPhieu: null,
      dataMauPhieu: null,
      totalPages: 0,
      totalRows: 0,
      total: 0,
      isShow: false,
      isLoading: true,
      tongSoHoSo: 0,
      title: '',
      hideHeader: false,
      componentName: 'BanGiaoNhieuHoSoForm',
      isShowFooter: false,
      danhSachNhomPhieu: [],
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã biên nhận',
          field: 'code',
          width: '200px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          width: '250px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'tenTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Chi tiết',
          field: 'tenLoaiTrangThai',
          width: '150px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          thClass: 'text-center',
          tdClass: 'vertical-align-middle',
          field: 'actions',
          width: '100px',
        },
      ],
      rows: [],
      selectedItems: null,
      selectedNumber: 0,
      selectedRows: [],
      danhSachThuTucHanhChinh: [],
      loaiTrangThaiId: [],
      duongDan: null,
      filter: {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: '',
        maHoSo: '',
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      },
      danhSachThoiHan: [
        {
          id: 1,
          label: 'Còn hạn',
        },
        {
          id: 2,
          label: 'Quá hạn',
        },
      ],
      detail: null,
      loaiTrangThaiConHanQuaHan: null,
      trangThaiConHanQuaHan: null,
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenIn() {
      return hasPermission([PERMISSION_NAME.BAN_GIAO_HO_SO.IN])
    },
    checkQuyenBanGiao() {
      return hasPermission([PERMISSION_NAME.BAN_GIAO_HO_SO.BAN_GIAO])
    },
    checkQuyenChiTiet() {
      return hasPermission([PERMISSION_NAME.BAN_GIAO_HO_SO.CHI_TIET])
    },
    checkAllQuyen() {
      return [this.checkQuyenChiTiet, this.checkQuyenIn].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    if (!_isEmpty(this.$route.query)) {
      this.isShow = true
    }
    this.filterDanhSachHoSo()
    this.getThuTucHanhChinh()
  },
  methods: {
    formatDateTime,
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.danhSachThuTucHanhChinh.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    detailHoSo(data) {
      this.$router.push({
        path: '/ho-so/chi-tiet',
        query: { id: data.id, name: this.$route.name },
      })
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.name),
      }
    },
    setItemUpdate(item) {
      this.selectedRows = [item]
    },
    filterDanhSachHoSo(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
      }
      if (this.$refs.hoSo) {
        payload = {
          ...payload,
          ...this.$refs.hoSo.getCurrentPage(),
        }
      }
      if (this.filter.trangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.loaiTrangThaiConHanQuaHan) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.dataTTHCId.length) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (!_isEmpty(this.$route.query)) {
        payload = {
          ...payload,
          thoiGianTiepNhanTu: this.$route.query.tuNgay,
          thoiGianTiepNhanDen: this.$route.query.denNgay,
        }
      }
      if (this.$refs.formFilter) {
        payload = {
          ...payload,
          ...this.$refs.formFilter.getFormFilter(),
        }
      }
      if (param.chuHoSo_HoVaTen) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          chuHoSo: param.chuHoSo_HoVaTen ? param.chuHoSo_HoVaTen.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.code) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          maHoSo: param.code ? param.code.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.chuHoSo_DiaChiDayDu) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          diaChiChuHoSo: param.chuHoSo_DiaChiDayDu ? param.chuHoSo_DiaChiDayDu.replace(/\s+/g, ' ').trim() : '',
        }
      }
      this.$axios
        .post(
          END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.DANH_SACH_HO_SO_BO_SUNG,
          this.$trimObject({
            ...payload,
          }),
          false,
        )
        .then(response => {
          if (response.data && response.data.succeeded) {
            this.rows = response.data.data
            this.tongSoHoSo = response.data.totalCount || 0
            this.$refs.hoSo.resetCurrentPage(payload.pageNumber)
          }
        })
    },
    getThuTucHanhChinh() {
      this.$axios.get(END_POINTS.THU_TUC_HANH_CHINH.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.danhSachThuTucHanhChinh = res.data.data
        }
      })
    },
    onFilter() {
      this.isShow = !this.isShow
    },
    onSelectRow(rows) {
      this.selectedRows = rows
      this.selectedItems = rows
      this.selectedNumber = rows.length
    },
    onBGBtn() {
      this.$refs.xuLyHoSo.show(this.selectedItems)
    },
    onPrint(data) {
      if (data?.loaiTrangThaiId) {
        this.$axios.get(`${END_POINTS.MAU_PHIEU.LAY_TRANG_THAI_ID}?loaiTrangThaiId=${data.loaiTrangThaiId}`).then(res => {
          if (res.data?.succeeded) {
            this.hoSo = data
            this.danhSachNhomPhieu = res.data.data
            this.$refs.printModal.showModal()
          }
        })
      } else {
        this.$toast.error('Hồ sơ không có loại trạng thái!')
      }
    },
    onClickToPrinter(dataMauPhieu) {
      this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET}?id=${this.hoSo?.id}`).then(res => {
        if (res.data?.succeeded) {
          this.$refs.printModal.hideModal()
          const dataHoSo = res.data.data
          this.mauPhieu = dataMauPhieu
          const regEx = /\{{(.*?)\}}/g
          let match = null
          // eslint-disable-next-line no-cond-assign
          while ((match = regEx.exec(this.mauPhieu.duongDan)) !== null) {
            if (match[1]) {
              let format = dataHoSo[`${match[1]}`]
              if (match[0].indexOf('ngay') >= 0) {
                let typeDate = 'DD/MM/YYYY'
                let dateTransfer = dataHoSo[`${match[1]}`]
                if (match[1].indexOf('|') >= 0) {
                  const dateForm = match[1].split('|')
                  // eslint-disable-next-line prefer-destructuring
                  typeDate = dateForm[1]
                  // eslint-disable-next-line prefer-destructuring
                  dateTransfer = dataHoSo[`${dateForm[0]}`]
                }
                if (match[0].indexOf('ngayHienTai') >= 0) {
                  dateTransfer = new Date()
                }
                format = this.$print.formatFullDateTime(dateTransfer, typeDate)
              } else if (match[0].indexOf('hoSoGiayToKhacModels') >= 0) {
                format = this.$print.formatStringTable(dataHoSo[`${match[1]}`])
              } else if (match[0].indexOf('Models') >= 0) {
                format = this.$print.formatTable(dataHoSo[`${match[1]}`])
              } else if (match[0].indexOf('CodeBase64') >= 0) {
                format = this.$print.formatImg(dataHoSo[`${match[1]}`], match[0])
              } else if (['gioHenTraDen', 'gioHenTraTu'].includes(match[1])) {
                format = this.$print.formatGio(dataHoSo[`${match[1]}`])
              }
              this.mauPhieu.duongDan = this.mauPhieu.duongDan.replace(match[0], format || '')
            }
          }
          this.dataMauPhieu = dataHoSo
          this.$refs.InTheoBieuMau.show()
        }
      })
    },
    resetFilter() {
      this.filter = {
        dataTTHCId: [],
        dataLinhVucId: [],
        fulltextsearch: '',
        chuHoSo: null,
        maHoSo: null,
        diaChiChuHoSo: '',
        thoiGianTiepNhanTu: '',
        thoiGianTiepNhanDen: '',
        thoiGianHenTraTu: '',
        thoiGianHenTraDen: '',
        trangThaiConHanQuaHan: null,
        loaiTrangThaiConHanQuaHan: null,
        trangThaiHoSo: [],
        loaiTrangThaiHoSo: [],
        navCode: this.$route.meta.code,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.filterDanhSachHoSo()
      })
    },
    refreshCommon() {
      if (this.$refs.formFilter) {
        this.$refs.formFilter.resetForm()
      }
      this.$refs.hoSo.$refs.vbTables.reset()
      this.resetFilter()
      this.filterDanhSachHoSo()
    },
    hide() {
      this.$refs.InTheoBieuMau.hide()
    },
    onClickToPrint() {
      this.$print.onClickToPrint(this)
    },
    onExportFileWord() {
      this.$print.onExportFileWord(this)
    },
    hideModal() {
      this.$refs.commomnModal.hideModal()
    },
    pageChange() {
      const data = this.$refs.hoSo.getCurrentPage()
      const filterAdv = this.$refs.formFilter ? this.$refs.formFilter.getFormFilter() : {}
      this.payload = {
        ...this.filter,
        ...filterAdv,
        trangThaiConHanQuaHan: this.filter.trangThaiConHanQuaHan || 0,
        loaiTrangThaiConHanQuaHan: this.filter.loaiTrangThaiConHanQuaHan || 0,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios
        .post(
          END_POINTS.TIEP_NHAN_HO_SO_BO_SUNG.DANH_SACH_HO_SO_BO_SUNG, this.payload, false,
        )
        .then(response => {
          if (response.data && response.data.succeeded) {
            this.rows = response.data.data
            this.tongSoHoSo = response.data.totalCount || 0
          }
        })
    },
    advancedSearch() {
      this.payload = {
        ...this.payload,
        ...this.$refs.formFilter.getFormFilter(),
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.DANH_SACH, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .card .card-header {
    border-bottom: 1.5px solid #EEEEEE !important;
    display: inline-block;
    padding: 0.5rem 1.5rem !important;
  }
  .cs-table {
    min-height: 150px;
    .actions-col-width {
      max-width: 50px;
    }
  }
  .font-weight-bold-700 {
    font-weight: 700;
    color: #333333;
  }
  .custom-column {
  p {
    margin-bottom: 0;
  }
}

.text-top{
  margin-top:6px;
}
</style>
