<template>
  <ban-giao-ho-so />
</template>
<script>
import BanGiaoHoSo from '@/modules/xu-ly-ho-so/components/pages/ban-giao-ho-so/BanGiaoHoSo.vue'

export default {
  components: {
    BanGiaoHoSo,
  },
}
</script>
