<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Ý kiến xử lý"
      label-for="textarea"
    >
      <b-form-textarea
        id="textarea"
        type="text"
        placeholder="Nhập ý kiến xử lý"
        rows="3"
        max-rows="6"
      />
    </b-form-group>
    <app-table
      class="mt-1"
      :columns="columns"
      :table-data="tableData"
    />
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import AppTable from '@/components/AppTable.vue'
import {
  Trash2Icon,
} from 'vue-feather-icons'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
    AppTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        {
          field: 'STT',
          key: 'STT',
          title: 'STT',
          width: 50,
          align: 'center',
          renderBodyCell: ({ rowIndex }) => rowIndex + 1,
        },
        {
          field: 'code',
          key: 'code',
          title: 'Danh sách hồ sơ',
          align: 'left',
        },
        {
          field: 'step',
          key: 'step',
          width: 200,
          title: 'Bước xử lý',
          align: 'left',
          renderBodyCell: () => (
            <span>
              <v-select
                vBlind:options="['Có thường gặp', 'Không thường gặp']"
                placeholder="Chọn bước xử lý"
              />
            </span>
          ),
        },
        {
          field: 'people',
          key: 'people',
          width: 200,
          title: 'Người nhận xử lý tiếp theo',
          align: 'left',
          renderBodyCell: () => (
            <span>
              <v-select
                class="style-chooser"
                vBlind:options="['Có thường gặp', 'Không thường gặp']"
                placeholder="Chọn người nhận xử lý tiếp theo"
              />
            </span>
          ),
        },
        {
          field: 'actions',
          key: 'actions',
          title: 'Thao tác',
          align: 'center',
          width: 100,
          renderBodyCell: () => (
            <span>
              <Trash2Icon
                size="17"
                class="custom-class mr-1 cursor-pointer"
              />
            </span>
          ),
        },
      ],
    }
  },
}
</script>
